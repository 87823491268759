import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {EmployeesService} from '../../../shared/services/employees.service';
import {ChatModalComponent} from '../chat-modal/chat-modal.component';
import {Router} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import {ShiftsService} from '../../../shared/services/shifts.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {TooltipShiftsModalsComponent} from '../tooltip-shifts-modals/tooltip-shifts-modals.component';
import {HelperService} from '../../../shared/services/helper.service';
import {DateTime} from "luxon";
import {AuthConst} from "../../../shared/services/const/authConst";

@Component({
  selector: 'app-shift-deteils',
  templateUrl: './shift-deteils.component.html',
  styleUrls: ['./shift-deteils.component.scss'],
})
export class ShiftDeteilsComponent implements OnInit {
  toDisable = true;
  shiftsForm: FormGroup = this.fb.group({
    date: [{value: '', disabled: false}],
    firstStartTime: [{value: '', disabled: false}],
    firstEndTime: [{value: '', disabled: false}],
    position: [{value: '', disabled: false}],
    firstActivity: [{value: '', disabled: false}],
    competence: [{value: '', disabled: false}],
    undisclosedNote: [{value: '', disabled: false}],
    disclosedNote: [{value: '', disabled: false}],
    secondActivity: [{value: '', disabled: false}],
    secondStartTime: [{value: '', disabled: false}],
    secondEndTime: [{value: '', disabled: false}],
  });

  createdShift: string;
  chatId: number;
  positions: any = [];
  competences: any = [];
  activities: any = [];
  dateChangable = true;
  displayPositions = false;
  displayCompetence = false;
  displayActivities = false;
  secondActivities = false;
  displayChat = false;
  nrOfAppliedApplications: number;
  assignedToId: any;
  cancelEdit = true;
  steps = 0;
  newDate: any = null;

  setPosition: string | undefined;
  setCompetence: string | undefined;
  setActivity: string | undefined;
  setActivity2: string | undefined;
  color: string | undefined;
  positionId: number | undefined;
  competenceId: number | undefined;
  activityId: number | undefined;
  setActivity2Id: number | undefined;
  role = "";

  constructor(
    public dialogRef: MatDialogRef<ShiftDeteilsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private employeesService: EmployeesService,
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private shiftService: ShiftsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private helperService: HelperService
  ) {
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('gapUserRole');
    if (this.setActivity2Id === null) {
      this.cancelEdit = false;
      this.shiftsForm.get('secondStartTime').disable();
      this.shiftsForm.get('secondEndTime').disable();
    } else {
      this.cancelEdit = true;
      this.shiftsForm.get('date').enable();
      this.shiftsForm.get('firstStartTime').enable();
      this.shiftsForm.get('firstEndTime').enable();
      this.shiftsForm.get('position').enable();
      this.shiftsForm.get('firstActivity').enable();
      this.shiftsForm.get('competence').enable();
      this.shiftsForm.get('undisclosedNote').enable();
      this.shiftsForm.get('disclosedNote').enable();
      this.shiftsForm.get('secondActivity').enable();
      this.shiftsForm.get('secondStartTime').enable();
      this.shiftsForm.get('secondEndTime').enable();
    }
    this.employeesService.userPositions().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId === this.data.person.groupId &&
          group.groupStatus === 'ACTIVE'
        ) {
          this.positions.push(group);
          this.positions.forEach((position: any) => {
            if (position.id === this.data.person?.position) {
              this.positionId = position.id;
              this.shiftsForm.patchValue({
                position: position.title,
              });
            }
          });
        }
      });
    });

    this.employeesService.userActvity().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId === this.data.person?.groupId &&
          group.groupStatus === 'ACTIVE'
        ) {
          this.activities.push(group);
          this.activities.forEach((activity: any) => {
            if ((activity.id === this.data.person?.activity1Id) || (activity.id === this.data.person?.activityId)) {
              this.activityId = activity.id;
              if (activity.shortTitle) {
                this.setActivity =
                  activity.title + ' (' + activity.shortTitle + ')';
              } else {
                this.setActivity = activity.title;
              }
              this.shiftsForm.patchValue({
                firstActivity: this.setActivity,
              });
            }
            if (activity.id === this.data.person?.activity2Id) {
              this.setActivity2Id = activity.id;
              if (activity.shortTitle) {
                this.setActivity2 =
                  activity.title + ' (' + activity.shortTitle + ')';
              } else {
                this.setActivity2 = activity.title;
              }
              this.shiftsForm.patchValue({
                secondActivity: this.setActivity2,
              });
            }
          });
        }
      });
    });

    this.employeesService.userCompetences().subscribe((x) => {
      x.forEach((group: any) => {
        if (
          group.groupId === this.data.person.groupId &&
          group.groupStatus === 'ACTIVE'
        ) {
          this.competences.push(group);
          this.competences.forEach((competence: any) => {
            if ((competence.id === this.data.person?.competence1Id) || (competence.id === this.data.person?.competenceId)) {
              this.competenceId = competence.id;
              this.shiftsForm.patchValue({
                competence: competence.title,
              });
            }
          });
        }
      });
    });

    this.createdShift = this.datePipe.transform(
      this.data.person.start.replace(/-/g, '/'),
      'EEEE d MMMM');

    if (this.data.person.dateChangeable === false) {
      this.dateChangable = this.data.person.dateChangeable;
    }

    this.newDate = new Date(this.data.person.start);

    this.shiftsForm.patchValue({
      date: this.createdShift,
      firstStartTime: this.calculateFirstTime(this.data.person.status, this.data.person.start),
      firstEndTime: this.calculateFirstTime(this.data.person.status, this.data.person.end),
      position: this.data.person.positionName,
      firstActivity: this.data.person.activity1Title,
      competence: this.data.person.competence1Name,
      undisclosedNote: this.data.person.description,
      disclosedNote: this.data.person.employerNote,
      secondActivity: this.data.person.activity2Title,
      secondStartTime: this.calculateSecondTime(this.data.person.status, this.data.person.activity2Start),
      secondEndTime: this.calculateSecondTime(this.data.person.status, this.data.person.activity2End),
    });

    console.log(this.shiftsForm);

    if (this.data.person.activity1Id) {
      this.activityId = this.data.person.activity1Id;
    }
  }

  // CHANGE DATE TYPE FOR IOS DEVICES
  calculateFirstTime(status: string, time: string): any {
    if (time) {
      if (status === 'ASSIGNED' && time) {
        return this.datePipe.transform(time.replace(/-/g, '/'), 'HH:mm').toString();
      } else if (status === 'ASSIGNED') {
        return this.datePipe.transform(time.replace(/-/g, '/'), 'HH:mm').toString();
      } else if (status === 'PUBLISHED') {
        return this.datePipe.transform(time.replace(/-/g, '/'), 'HH:mm').toString();
      } else if (status === 'PUBLISHED' && time) {
        return this.datePipe.transform(time, 'HH:mm').toString();
      } else if (status === 'CANCELED') {
        return this.datePipe.transform(time.replace(/-/g, '/'), 'HH:mm').toString();
      } else if (status === 'CANCELED' && time) {
        return this.datePipe.transform(time, 'HH:mm').toString();
      }
    }
  }

  // CHANGE DATE TYPE FOR IOS DEVICES
  calculateSecondTime(status: string, time: string): any {
    if (time) {
      if (status === 'ASSIGNED' && time) {
        return this.datePipe.transform(time.replace(/-/g, '/'), 'HH:mm').toString();
      } else if (status === 'ASSIGNED') {
        return this.datePipe.transform(time, 'HH:mm').toString();
      } else if (status === 'PUBLISHED') {
        return this.datePipe.transform(time.replace(/-/g, '/'), 'HH:mm').toString();
      } else if (status === 'PUBLISHED' && time) {
        return this.datePipe.transform(time, 'HH:mm').toString();
      } else if (status === 'CANCELED') {
        return this.datePipe.transform(time.replace(/-/g, '/'), 'HH:mm').toString();
      } else if (status === 'CANCELED' && time) {
        return this.datePipe.transform(time, 'HH:mm').toString();
      }
    }
  }

  openChat(event: any): void {
    if (this.data.person.status === 'CANCELED') {
      event.stopPropagation();
    } else {
      // @ts-ignore
      const person = this.data.person;
      const confirmDialog = this.dialog.open(ChatModalComponent, {
        disableClose: true,
        width: '560px',
        height: '650px',
        panelClass: 'chatDialog',
        position: {
          top: '100px',
        },
        data: {
          person,
        },
      });
      confirmDialog.afterClosed().subscribe(() => {
      });
    }
  }

  capitalize(status: string): string {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  assignShift(person: any): void {
    const shiftObj = {
      id: person.id,
      name: '',
      groupId: person.groupId,
      start: this.datePipe.transform(person.start.replace(/-/g, '/'), 'HH:mm').toString(),
      end: this.datePipe.transform(person.end.replace(/-/g, '/'), 'HH:mm').toString(),
      positionId: person.position,
      activityId: person.activity1Id,
      competenceId: person.competence1Id,
      undisclosedNote: person.description,
      disclosedNote: person.employerNote,
      activity2Id: person.activity2Id,
      start2: person.activity2Start
        ? this.datePipe.transform(person.activity2Start.replace(/-/g, '/'), 'HH:mm').toString()
        : '',
      end2: person.activity2End
        ? this.datePipe.transform(person.activity2End.replace(/-/g, '/'), 'HH:mm').toString()
        : '',
      color: person.positionColor,
      clinical: person.clinical,
      applicationsCount: person.nrOfAppliedApplications,
      nrOfAppliedApplications: person.nrOfAppliedApplications,
      type: 2,
      status: person.status,
      date: this.setCorrectFormatDate(person.start),
    };

    localStorage.setItem('selectedShift', JSON.stringify(shiftObj));
    this.closeModal();
    this.router.navigate(['assignment/details']);
  }

  // this.datePipe.transform(person.start.replace(/-/g, '/'), 'yyyy-MM-dd').toString(),
  setCorrectFormatDate(time: string): any {
    if (time) {
      return this.datePipe.transform(time.replace(/-/g, '/'), 'yyyy-MM-dd').toString();
    }
  }

  getPositions(): void {
    this.displayPositions = !this.displayPositions;
  }

  chosePosition(position: any): void {
    if (position !== null) {
      this.color = '#' + position.color;
      this.positionId = position.id;
      this.shiftsForm.patchValue({
        position: position.title,
      });
    } else {
      this.positionId = null;
      this.shiftsForm.patchValue({
        position: this.translate.instant('select'),
      });
    }
  }

  getActivities(): void {
    this.displayActivities = !this.displayActivities;
  }

  choseActivity(activities: any): void {
    if (activities !== null) {
      if (activities.shortTitle) {
        this.setActivity =
          activities.title + ' (' + activities.shortTitle + ')';
      } else {
        this.setActivity = activities.title;
      }
      this.activityId = activities.id;
      this.shiftsForm.patchValue({
        firstActivity: this.setActivity,
      });
    } else {
      this.activityId = null;
      this.shiftsForm.patchValue({
        firstActivity: this.translate.instant('select'),
      });
    }
  }

  getCompetences(): void {
    this.displayCompetence = !this.displayCompetence;
  }

  choseCompetence(competence: any): void {
    if (competence !== null) {
      this.competenceId = competence.id;
      this.shiftsForm.patchValue({
        competence: competence.title,
      });
    } else {
      this.competenceId = null;
      this.shiftsForm.patchValue({
        competence: this.translate.instant('select'),
      });
    }
  }

  secondActivity(e: any): void {
    this.secondActivities = !this.secondActivities;
    e.stopPropagation();
    e.preventDefault();
  }

  getSecondActivity(activity: any): void {
    if (activity !== null) {
      this.setActivity2Id = activity.id;
      this.shiftsForm.patchValue({
        secondActivity: activity.title,
      });
    } else {
      this.setActivity2Id = null;
      this.shiftsForm.patchValue({
        secondActivity: this.translate.instant('select'),
      });
    }
  }

  backToUser(): void {
    this.displayChat = false;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  routeToUser(event: any): void {
    if (this.data.person.status === 'CANCELED') {
      event.stopPropagation();
    } else {
      this.closeModal();
      this.userService
        .getEmployeeById(this.data.person.assignedToId)
        .subscribe((x) => {
          this.router.navigate([`personal/user/${x.id}`]);
        });
    }
  }

  getPersonShortName(positionId: any): string {
    let shortName = '';
    this.positions.forEach((person: { id: any; shortTitle: string }) => {
      this.nrOfAppliedApplications = this.data.person.nrOfAppliedApplications;
      this.assignedToId = this.data.person.assignedToId;
      if (person.id === positionId) {
        if (person.shortTitle) {
          shortName = person.shortTitle;
        }
      }
    });
    return shortName;
  }

  getPositionShortName(positionId: any): string {
    let shortName = '';
    this.positions.forEach((person: { id: any; shortTitle: string }) => {
        if (person.id === positionId) {
          shortName = person.shortTitle;
        }
    });

    return shortName;
  }

  timeStringToDate(timeString: string) {
    const today = new Date();

    const hours = timeString.substring(0, 2);
    const minutes = timeString.substring(3, 5);

    const modifiedDate = new Date(today.setHours(Number(hours), Number(minutes)));
    return modifiedDate;
  }

  calculateTimeLimit(type: string): any { //type can be min or max
    if (type == 'min') {
      if (this.shiftsForm.value.firstStartTime < this.shiftsForm.value.firstEndTime) {
        return this.shiftsForm.value.firstStartTime;
      } else {
        return null;
      }
    } else { // max
      if (this.shiftsForm.value.firstStartTime < this.shiftsForm.value.firstEndTime) {
        return this.shiftsForm.value.firstEndTime;
      } else {
        return null;
      }
    }
  }

  save(): void {
    let selectedPosition: null;
    const positionIndex = this.positions.findIndex(
      (p: any) => p.title == this.shiftsForm.value.position
    );
    if (positionIndex !== -1) {
      selectedPosition = this.positions[positionIndex].id;
    } else {
      selectedPosition = null;
    }
    let selectedActivity2: null;
    const activity1Index2 = this.activities.findIndex(
      (a: any) => a.title == this.shiftsForm.value.secondActivity
    );
    if (activity1Index2 !== -1) {
      selectedActivity2 = this.activities[activity1Index2].id;
    } else {
      selectedActivity2 = null;
    }
    let selectedCompetence: null;
    const competenceIndex = this.competences.findIndex(
      (c: any) => c.title == this.shiftsForm.value.competence
    );
    if (competenceIndex !== -1) {
      selectedCompetence = this.competences[competenceIndex].id;
    } else {
      selectedCompetence = null;
    }
    let date = "";

    if (this.newDate) {
      date =
        this.newDate.getUTCFullYear() +
        '-' +
        Number(this.newDate.getMonth() + 1) +
        '-' +
      this.newDate.getDate();
    } else {
      date =
        new Date(this.data.person.start.replace(/-/g, '/')).getUTCFullYear() +
        '-' +
        Number(Number(new Date(this.data.person.start.replace(/-/g, '/')).getMonth()) + 1) +
        '-' +
        new Date(this.data.person.start.replace(/-/g, '/')).getDate();

    }

    const shift = {
      id: this.data.person.id,
      date,
      start: this.setCorrectFormat(date, this.shiftsForm.value.firstStartTime),
      end: this.setCorrectFormat(date, this.shiftsForm.value.firstEndTime),
      positionId: this.positionId,
      activity1Id: this.activityId,
      competence1Id: selectedCompetence,
      description: this.shiftsForm.value.undisclosedNote,
      employerNote: this.shiftsForm.value.disclosedNote,
      activity2Id: this.setActivity2Id,
      activity2Start: this.shiftsForm.value.secondStartTime
        ? this.setCorrectFormat(date, this.shiftsForm.value.secondStartTime)
        : null,
      activity2End: this.shiftsForm.value.secondEndTime
        ? this.setCorrectFormat(date, this.shiftsForm.value.secondEndTime)
        : null,
    };

    if (shift.activity2Start < shift.start || shift.activity2End > shift.end) {
      this.snackBar.open(this.translate.instant('selectLimit'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    if (shift.activity2Id != null && (shift.activity2Start == null || shift.activity2End == null)) {
      this.snackBar.open(this.translate.instant('selectActivityTime'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
      return;
    }

    if (shift.positionId !== null && shift.activity1Id !== null) {
      this.shiftService.editAssignment(shift).subscribe((data: any) => {
        if (this.data.showIcons != false) {
          window.location.reload();
        }

        localStorage.setItem(AuthConst.scheduleDate, this.datePipe.transform(new Date(shift.date), 'yyyy-MM-dd'));

        if (this.data.refresh) {
          window.location.reload();
        }

        setTimeout(() => {
          this.dialogRef.close(data);
          this.snackBar.open(this.translate.instant('saved'), 'OK', {
            duration: 3000,
          });
        }, 500);
      });
    } else {
      this.snackBar.open(this.translate.instant('fillAllFields'), 'OK', {
        duration: 3000,
        panelClass: ['custom-snack-bar'],
      });
    }
  }

  // FORMATTING DATE FOR IOS
  setCorrectFormat(date: string, hours: string): any {
    return this.datePipe.transform(date.replace(/-/g, '/') + ' ' + hours.replace(/-/g, '/'), 'yyyy-MM-dd HH:mm Z').toString();
  }

  recoveryShift(): void {
    let selectedPosition: null;
    const positionIndex = this.positions.findIndex(
      (p: any) => p.title == this.shiftsForm.value.position
    );

    if (positionIndex !== -1) {
      selectedPosition = this.positions[positionIndex].id;
    } else {
      selectedPosition = null;
    }

    const selectedActivity1 = this.activityId;

    const selectedActivity2 = this.data.person.activity2Id;
    // const activity1Index2 = this.activities.findIndex(
    //   (a: any) => a.title == this.shiftsForm.value.secondActivity
    // );
    // if (activity1Index2 !== -1) {
    //   selectedActivity2 = this.activities[activity1Index2].id;
    // } else {
    //   selectedActivity2 = null;
    // }

    let selectedCompetence: null;
    const competenceIndex = this.competences.findIndex(
      (c: any) => c.title == this.shiftsForm.value.competence
    );
    if (competenceIndex !== -1) {
      selectedCompetence = this.competences[competenceIndex].id;
    } else {
      selectedCompetence = null;
    }

    const date =
      new Date(this.data.person.start.replace(/-/g, '/')).getUTCFullYear() +
      '-' +
      Number(Number(new Date(this.data.person.start.replace(/-/g, '/')).getMonth()) + 1) +
      '-' +
      new Date(this.data.person.start.replace(/-/g, '/')).getDate();

    const shift = {
      id: this.data.person.id,
      date,
      start: this.setCorrectFormat(date, this.shiftsForm.value.firstStartTime),
      end: this.setCorrectFormat(date, this.shiftsForm.value.firstEndTime),
      positionId: selectedPosition,
      activity1Id: selectedActivity1,
      competence1Id: selectedCompetence,
      description: this.shiftsForm.value.undisclosedNote,
      employerNote: this.shiftsForm.value.disclosedNote,
      status: 3,
      cancelReasonId: this.data.person.cancelReasonId
        ? null
        : this.data.person.cancelReasonId,
      activity2Id: selectedActivity2 ? selectedActivity2 : null,
      activity2Start: this.shiftsForm.value.secondStartTime
        ? this.setCorrectFormat(date, this.shiftsForm.value.secondStartTime)
        : null,
      activity2End: this.shiftsForm.value.secondEndTime
        ? this.setCorrectFormat(date, this.shiftsForm.value.secondEndTime)
        : null,
    };

    this.shiftService
      .editAssignment(shift)
      .subscribe((data: any) => {
        this.saveAssignmentsToLocalStorage([data]);
        this.helperService.recoveredShift.next(data);
        this.dialogRef.close(data);
        this.snackBar.open(this.translate.instant('shiftRecovered'), 'OK', {
          duration: 5000,
        });
      });
  }

  deleteShift(): void {
    const confirmDialog = this.dialog.open(TooltipShiftsModalsComponent, {
      disableClose: true,
      width: '290px',
      height: '190px',
      panelClass: 'defaultDialog',
      position: {
        top: '150px',
      },
      data: {
        person: this.data.person,
        title: this.translate.instant('deleteCenceledShift'),
        subtitle: this.translate.instant('attention'),
        message: this.translate.instant('deleteCanceledShiftsText'),
        mode: 'delete',
      },
    });
    confirmDialog.afterClosed().subscribe((res) => {
      window.location.reload();
      this.removeAssignmentFromLocalStorage(this.data.person);
      this.dialogRef.close(res);
    });
  }

  removeAssignmentFromLocalStorage(assignment: any): void {

    let existingAssignments: any = localStorage.getItem('assignments');

    if (existingAssignments) {
      existingAssignments = JSON.parse(existingAssignments);

      const index = existingAssignments.findIndex((a: any) => {
        return a.id == assignment.id;
      });
      if (index !== -1) {
        existingAssignments.splice(index, 1);
        try {
          localStorage.setItem('assignments', JSON.stringify(existingAssignments));
        } catch (e) {

        }
      }
    }

  }

  saveAssignmentsToLocalStorage(assignments: any): void {

    let existingAssignments: any = localStorage.getItem('assignments');

    if (existingAssignments) {
      existingAssignments = JSON.parse(existingAssignments);
    } else {
      existingAssignments = [];
    }

    assignments.forEach((a: any) => {

      const assignmentIndex = existingAssignments.findIndex((assignment: any) => {
        return assignment.id == a.id;
      });

      if (assignmentIndex !== -1) {
        existingAssignments[assignmentIndex] = a;
      } else {
        existingAssignments.push(a);
      }

      try {
        localStorage.setItem('assignments', JSON.stringify(existingAssignments));
      } catch (e) {

      }

    });


  }

  sortPositions(): any[] {
    return this.positions.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
  }

  sortActivities(): any[] {
    return this.activities.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
  }

  sortCompetences(): any[] {
    return this.competences.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
  }

  changedDate(event: any): any {
    const date = new Date(event.value);
    this.newDate = date;
    this.createdShift = this.datePipe.transform(
      date,
      'EEEE d MMMM');
  }
}
